import React from "react"

import Text from "@src/components/Text"
import Button from "@src/components/Button"

import { ReactComponent as TemplateTitleIcon } from "@src/icons/template-title.svg"

import getAppUrl from "@src/utils/getAppUrl"
import * as styles from "./FilteredData.module.scss"

const AllData = ({ filteredData, location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/samples")
  return filteredData.map(project => (
    <div className={styles.samplesCard}>
      <Text.H3 grey>
        <span className={styles.templateTitle}>
          <TemplateTitleIcon className={styles.templateTitleIcons} />
          {project.title}
        </span>
      </Text.H3>
      <Text.P grey>{project.description}</Text.P>
      <div className={styles.samplesBottomWrapper}>
        <span className={styles.samplesProjectType}>{project.projectType}</span>
        <span className={styles.samplesButtonWrapper}>
          <a href={`${mainAppUrl}`} target="_blank" rel="noreferrer">
            <Button className={styles.samplesButtonWrapperTransparentButton}>
              View
            </Button>
          </a>
          <a href={`${mainAppUrl}`} target="_blank" rel="noreferrer">
            <Button isPrimary>Clone</Button>
          </a>
        </span>
      </div>
    </div>
  ))
}

export default AllData
