// extracted by mini-css-extract-plugin
export var samples = "FilteredData-module--samples--2YHm-";
export var samplesBottomWrapper = "FilteredData-module--samplesBottomWrapper--DJj5w";
export var samplesButton = "FilteredData-module--samplesButton--3xB5j";
export var samplesButtonWrapper = "FilteredData-module--samplesButtonWrapper--1EK0T";
export var samplesButtonWrapperTransparentButton = "FilteredData-module--samplesButtonWrapperTransparentButton--3dhLb";
export var samplesCard = "FilteredData-module--samplesCard--2KRuY";
export var samplesProjectType = "FilteredData-module--samplesProjectType--2IZG1";
export var showIn = "FilteredData-module--show-in--3Pg35";
export var templateTitle = "FilteredData-module--templateTitle--tia3Y";
export var templateTitleIcons = "FilteredData-module--templateTitleIcons--23LYI";