import React from "react"

import Section from "@src/components/Section"

import * as styles from "./Dropdown.module.scss"
const Dropdown = ({
  onProjectTypeChange,
  onSearch,
  industries,
  projectTypes,
}) => {
  return (
    <Section noMargin>
      <div className={styles.filter}>
        <div>
          <select onChange={onProjectTypeChange}>
            <option disabled selected>
              Project Type
            </option>
            {projectTypes.map((type, key) => (
              <option key={key}>{type}</option>
            ))}
          </select>
          <select onChange={onProjectTypeChange}>
            <option disabled selected>
              Industry
            </option>
            {industries.map((industry, key) => (
              <option key={key}>{industry}</option>
            ))}
          </select>
        </div>
        <div>
          <input
            type="text"
            placeholder="search..."
            onChange={event => onSearch(event.target.value)}
          />
        </div>
      </div>
    </Section>
  )
}

export default Dropdown
