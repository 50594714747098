import React, { useState } from "react"
import Fuse from "fuse.js"

import Section from "@src/components/Section"
import Dropdown from "../Dropdown/Dropdown"

import AllData from "../Data/AllData"
import FilteredData from "../Data/FilteredData"
import SearchableData from "../Data/SearchableData"

import SampleProjectsData from "@src/data/SampleProjectData.json"
import * as styles from "./SampleProjects.module.scss"

const SampleProjects = () => {
  const fuse = new Fuse(SampleProjectsData, {
    keys: ["title"],
  })

  const projectType = [
    ...new Set(SampleProjectsData.map(project => project.projectType)),
  ]
  const industry = [
    ...new Set(SampleProjectsData.map(project => project.industry)),
  ]

  const [projectTypes, setProjectTypes] = useState(projectType)
  const [selectedProjectType, setSelectedProjectType] = useState("all")
  const [industries, setindustries] = useState(industry)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [filterData, setFilterData] = useState(true)

  let sortedSearchResults = ""
  if (searchResults.length > 1) {
    sortedSearchResults = searchResults.sort((resultA, resultB) => {
      return resultA.score - resultB.score
    })
  }

  const handleProjectTypeChange = React.useCallback(
    e => {
      setSelectedProjectType(e.target.value)
      setSearchResults("")
    },
    [selectedProjectType]
  )

  const handleSearch = React.useCallback(
    searchQuery => {
      const results = fuse.search(searchQuery)
      setSearchQuery(searchQuery)
      setSearchResults(results)
      setFilterData(false)
    },

    [sortedSearchResults]
  )

  let filteredData = React.useMemo(
    () =>
      SampleProjectsData.filter(project => {
        return (
          project.projectType === selectedProjectType ||
          project.industry === selectedProjectType
        )
      }),
    [handleProjectTypeChange]
  )

  return (
    <>
      <Dropdown
        onProjectTypeChange={handleProjectTypeChange}
        onSearch={handleSearch}
        industries={industries}
        projectTypes={projectTypes}
      />

      <Section className={styles.templates}>
        <div className={styles.samples}>
          {filterData &&
          selectedProjectType === "all" &&
          sortedSearchResults.length === 0 ? (
            <AllData SampleProjectsData={SampleProjectsData} />
          ) : (
            <FilteredData filteredData={filteredData} />
          )}
          {sortedSearchResults.length > 0 && (
            <SearchableData sortedSearchResults={sortedSearchResults} />
          )}
        </div>
      </Section>
    </>
  )
}
export default SampleProjects
