import React from "react"

import CommunityCallout from "@src/components/CommunityCallout"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import SampleProjects from "./components/SampleProjects"
import getAppUrl from "@src/utils/getAppUrl"

import template from "@src/icons/screenshots/template.png"
import OG from "@src/icons/og/OG_Template.jpeg"
import * as styles from "./Templates.module.scss"

const Templates = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/samples")
  return (
    <Layout
      title="Templates"
      description="Clone a product template & tailor it to your needs"
      url="https://canonic.dev/templates"
      ogImage={OG}
    >
      <SplashWithIllustration
        isWhite
        screenshot={template}
        superscript="Template Projects "
        title="Clone a product template & tailor it to your needs"
        subtitle="Deliver value to your users by defining your business logic using simple blocks on our intuitive low-code platform"
        className={styles.section}
        buttons={[
          {
            title: "View Templates",
            isPrimary: true,
            href: `${mainAppUrl}`,
          },
        ]}
      />
      <SampleProjects />
      <CommunityCallout />
    </Layout>
  )
}

export default Templates
