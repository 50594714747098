// extracted by mini-css-extract-plugin
export var filter = "SampleProjects-module--filter--3KeEx";
export var samples = "SampleProjects-module--samples--3ozkD";
export var samplesBottomwrapper = "SampleProjects-module--samplesBottomwrapper--2yuDv";
export var samplesButton = "SampleProjects-module--samplesButton--1xqPB";
export var samplesButton_wrapper = "SampleProjects-module--samplesButton_wrapper--3CrsJ";
export var samplesButton_wrapperTransparent_button = "SampleProjects-module--samplesButton_wrapperTransparent_button--3ia6G";
export var samplesCard = "SampleProjects-module--samplesCard--pEtST";
export var samplesProjecttype = "SampleProjects-module--samplesProjecttype--1R7xG";
export var showIn = "SampleProjects-module--show-in--2BWDT";
export var templates = "SampleProjects-module--templates--2hBE5";